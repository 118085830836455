<template>
  <n-message-provider>
    <div class="main-container">
      <logout-link v-if="isAuthenticated" />
      <div class="profile-container">
        <div class="profile-card">
          <slot />
        </div>
      </div>
    </div>
  </n-message-provider>
</template>

<script setup lang="ts">
import {NMessageProvider} from "naive-ui"
import {storeToRefs} from "pinia";
import {useAuthStore} from "~/store/auth";
const isAuthenticated = storeToRefs(useAuthStore()).isAuthenticated
</script>


<style scoped>
.main-container {
  background: #F5F5F5;
}

.profile-card {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 800px;
  height: auto;
  left: 0px;
  top: 230px;
  margin: auto;

  background: #FFFFFF;
  border-radius: 12px 12px 0px 0px;
}
</style>
