<template>
  <n-button text @click="authStore.logout">
    <template #icon>
      <n-icon>
        <nuxt-icon name="logout" />
      </n-icon>
    </template>
    Logout
  </n-button>
</template>

<script setup lang="ts">
  import { NButton, NIcon } from "naive-ui"
  import {useAuthStore} from "~/store/auth";

  const authStore = useAuthStore()
</script>